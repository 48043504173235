<template>
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlns:xlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 46 46'
        stroke='currentColor'
        fill='currentColor'
    >
        <defs>
            <clipPath id='clippath'>
                <rect
                    x='2.18'
                    y='1.68'
                    width='41.65'
                    height='42.65'
                />
            </clipPath>
            <clipPath id='clippath-1'>
                <rect
                    x='2.18'
                    y='1.68'
                    width='41.65'
                    height='42.65'
                />
            </clipPath>
            <clipPath id='clippath-2'>
                <rect
                    x='8.25'
                    y='8.68'
                    width='29.5'
                    height='3'
                />
            </clipPath>
            <clipPath id='clippath-3'>
                <rect
                    x='8.25'
                    y='15.68'
                    width='29.5'
                    height='3'
                />
            </clipPath>
            <clipPath id='clippath-4'>
                <rect
                    x='8.25'
                    y='22.68'
                    width='20.5'
                    height='3'
                />
            </clipPath>
        </defs>
        <g>
            <g
                id='Group_19640'
                data-name='Group 19640'
            >
                <g
                    id='Group_19639'
                    data-name='Group 19639'
                >
                    <g>
                        <g
                            id='Group_19638'
                            data-name='Group 19638'
                        >
                            <g
                                id='Group_19631'
                                data-name='Group 19631'
                            >
                                <g
                                    id='Group_19630'
                                    data-name='Group 19630'
                                >
                                    <g>
                                        <g
                                            id='Group_19629'
                                            data-name='Group 19629'
                                        >
                                            <line
                                                id='Line_1126'
                                                data-name='Line 1126'

                                                x1='36.25'
                                                y1='10.18'
                                                x2='9.75'
                                                y2='10.18'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g
                                id='Group_19634'
                                data-name='Group 19634'
                            >
                                <g
                                    id='Group_19633'
                                    data-name='Group 19633'
                                >
                                    <g>
                                        <g
                                            id='Group_19632'
                                            data-name='Group 19632'
                                        >
                                            <line
                                                id='Line_1127'
                                                data-name='Line 1127'
                                                x1='36.25'
                                                y1='17.18'
                                                x2='9.75'
                                                y2='17.18'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g
                                id='Group_19637'
                                data-name='Group 19637'
                            >
                                <g
                                    id='Group_19636'
                                    data-name='Group 19636'
                                >
                                    <g>
                                        <g
                                            id='Group_19635'
                                            data-name='Group 19635'
                                        >
                                            <line
                                                id='Line_1128'
                                                data-name='Line 1128'
                                                x1='27.25'
                                                y1='24.18'
                                                x2='9.75'
                                                y2='24.18'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <path
                                id='Path_19819'
                                data-name='Path 19819'
                                d='m37.97,1.68H8.03c-3.23,0-5.85,2.62-5.86,5.86v30.94c0,3.23,2.62,5.85,5.86,5.86h29.94c3.23,0,5.85-2.62,5.86-5.86V7.53c0-3.23-2.62-5.85-5.86-5.86M5.07,7.53c0-1.63,1.33-2.96,2.96-2.96h29.94c1.63,0,2.96,1.33,2.96,2.96v30.94c0,1.63-1.33,2.96-2.96,2.96H8.03c-1.63,0-2.96-1.33-2.96-2.96V7.53Z'
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
