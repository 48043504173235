<template>
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlns:xlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 46 46'
        stroke='currentColor'
        fill='currentColor'
    >
        <defs>
            <clipPath id='clippath'>
                <rect
                    x='2.34'
                    y='1.55'
                    width='41.33'
                    height='42.9'
                />
            </clipPath>
            <clipPath id='clippath-1'>
                <rect
                    x='2.34'
                    y='1.55'
                    width='41.33'
                    height='42.9'
                />
            </clipPath>
            <clipPath id='clippath-2'>
                <rect
                    x='20.86'
                    y='15.62'
                    width='4.29'
                    height='21.58'
                />
            </clipPath>
        </defs>
        <g>
            <g
                id='Group_19627'
                data-name='Group 19627'
            >
                <g
                    id='Group_19626'
                    data-name='Group 19626'
                >
                    <g>
                        <g
                            id='Group_19625'
                            data-name='Group 19625'
                        >
                            <path
                                id='Path_19816'
                                data-name='Path 19816'
                                d='m37.74,44.45H8.27c-3.28,0-5.93-2.66-5.93-5.94V7.49c0-3.28,2.66-5.93,5.93-5.93h11.08c.5,0,.97.25,1.25.67l3.99,5.98h13.15c3.28,0,5.93,2.66,5.93,5.93v24.38c0,3.28-2.66,5.93-5.93,5.94M8.27,4.55c-1.62,0-2.93,1.31-2.93,2.93v31.03c0,1.62,1.31,2.93,2.93,2.94h29.47c1.62,0,2.93-1.31,2.93-2.93V14.13c0-1.62-1.31-2.93-2.93-2.93h-13.95c-.5,0-.97-.25-1.25-.67l-3.99-5.98h-10.28Z'
                            />
                            <g
                                id='Group_19624'
                                data-name='Group 19624'
                            >
                                <g
                                    id='Group_19623'
                                    data-name='Group 19623'
                                >
                                    <g>
                                        <g
                                            id='Group_19622'
                                            data-name='Group 19622'
                                        >
                                            <path
                                                id='Path_19817'
                                                data-name='Path 19817'
                                                d='m23.98,30.28l1.14-12.07c.05-.59-.09-1.17-.39-1.68-.35-.6-1.02-.96-1.71-.91-.69-.03-1.34.31-1.7.9-.32.51-.47,1.1-.42,1.7l1.14,12.06h1.95Z'
                                            />
                                            <path
                                                id='Path_19818'
                                                data-name='Path 19818'
                                                d='m24.56,33.28c-.73-.85-2.01-.94-2.86-.21-.07.06-.14.13-.2.2-.42.44-.65,1.03-.64,1.64-.01.6.21,1.19.63,1.62.38.43.93.67,1.5.68.58,0,1.12-.25,1.51-.67.43-.43.66-1.02.65-1.63.01-.59-.2-1.17-.59-1.62'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
