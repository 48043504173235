<script setup lang='ts'>

const lang = ref({ en: '中文', hk: 'ENG' })

</script>

<template>
    <button
        class='box-border w-12 rounded border-2 border-blue-500 px-0 py-1 text-xs font-medium text-blue-500 transition-colors duration-500 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-blue-500'
        type='button'
        @click='$i18n.setLocale($i18n.locale === "en" ? "hk" : "en")'
    >
        {{ lang[$i18n.locale as keyof typeof lang] }}
    </button>
</template>
