<script setup lang='ts'>

const store = useToastStore()

</script>

<template>
    <BaseModal
        v-if='store.shouldBlock'
        fade-on-click-outside
        :show='store.shouldShow'
        @close='store.$reset()'
        @click-outside='store.$reset()'
    >
        <BasePanel class='max-w-md self-start'>
            <ToastItem
                v-for='toast in store.toast'
                :key='toast.key'
                :toast='toast'
                @remove='store.remove(toast.key)'
            />
        </BasePanel>
    </BaseModal>

    <div
        v-else
        class='pointer-events-none fixed grid w-full justify-center pt-2 transition-all'
        :class='{ "opacity-0": !store.shouldShow }'
    >
        <div class='pointer-events-auto overflow-hidden rounded-md text-left shadow-xl'>
            <ToastItem
                v-for='toast in store.toast'
                :key='toast.key'
                :toast='toast'
                @remove='store.remove(toast.key)'
            />
        </div>
    </div>
</template>
