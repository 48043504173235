export default defineNuxtPlugin(nuxtApp => {
    const { handleServerError } = useServerErrorHandler(message => {
        if (nuxtApp && isRecord(nuxtApp.$i18n) && hasKey(nuxtApp.$i18n, 't') && isCallback(nuxtApp.$i18n.t)) {
            const translatedMessage = nuxtApp.$i18n.t(message)

            if (typeof translatedMessage === 'string') {
                return translatedMessage
            }
        }

        return message
    })

    nuxtApp.hook('vue:error', handleServerError)
})
