<template>
    <!-- eslint-disable-next-line -->
    <div class='flap-display'>
        <!-- eslint-disable-next-line -->
        <div class='display'>
            <!-- eslint-disable-next-line -->
            <span class='display-top'>{{ text }}</span>
            <!-- eslint-disable-next-line -->
            <span class='display-bottom'>{{ oldText }}</span>
        </div>
        <!-- eslint-disable-next-line -->
        <div class='flap'>
            <Transition
                name='flip-top'
                @after-enter='onFlipTopEnter'
            >
                <!-- eslint-disable-next-line -->
                <span v-if='isShown' class='flap-top'>{{ oldText }}</span>
            </Transition>
            <Transition name='flip-bottom'>
                <!-- eslint-disable-next-line -->
                <span v-if='isShown' class='flap-bottom'>{{ text }}</span>
            </Transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FlapDisplay',
    props: {
        text: { type: String, default: '' }
    },
    data() {
        return {
            oldText: '',
            isShown: false
        }
    },
    watch: {
        text() {
            this.isShown = true
        }
    },
    mounted() {
        if (this.oldText === '') {
            this.oldText = this.text
        };
    },
    methods: {
        onFlipTopEnter() {
            this.oldText = this.text
            this.isShown = false
        }
    }
}
</script>

<style scoped>
.flap-display {
  position: relative;
  color: white;
  text-align: center;
  font-size: 3.75rem;
  --flap-display-width: 5rem;
  --flap-display-height: 8rem;
  --flap-animation-time: 0.6s;
  --flap-animation-ease: linear;
  --flap-border-radius: 0.4rem;
  --flap-background-color: #212121;
}

@media screen and (min-width: 1024px) {
  .flap-display {
    font-size: 4.5rem;
    --flap-display-width: 7rem;
    --flap-display-height: 8rem;
  }
}

.display {
  position: relative;
  display: flex;
  flex-direction: column;
  width: var(--flap-display-width);
  height: var(--flap-display-height);
  font-size: var(--font-size);
}
.display-top,
.display-bottom {
  position: relative;
  width: var(--flap-display-width);
  height: var(--flap-display-height);
  overflow: hidden;
  background-color: var(--flap-background-color);
}
.display-top {
  line-height: var(--flap-display-height);
  border-top-left-radius: var(--flap-border-radius);
  border-top-right-radius: var(--flap-border-radius);
}
.display-bottom {
  line-height: 0;
  border-bottom-left-radius: var(--flap-border-radius);
  border-bottom-right-radius: var(--flap-border-radius);
}
.flap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: var(--flap-display-width);
  height: var(--flap-display-height);
}
.flap-top,
.flap-bottom {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  width: var(--flap-display-width);
  height: calc(var(--flap-display-height) * 0.5);
  background-color: var(--flap-background-color);
}
.flap-top {
  line-height: var(--flap-display-height);
  top: 0;
  transform-origin: bottom;
  transform: rotateX(-90deg);
  border-top-left-radius: var(--flap-border-radius);
  border-top-right-radius: var(--flap-border-radius);
}
.flap-bottom {
  line-height: 0;
  transform: rotateX(90deg);
  transform-origin: top;
  bottom: 0;
  border-bottom-left-radius: var(--flap-border-radius);
  border-bottom-right-radius: var(--flap-border-radius);
}

@keyframes flapTopAnimation {
  0% {
    transform: rotateX(0deg);
  }
  50%,
  100% {
    transform: rotateX(-90deg);
  }
}
@keyframes flapBottomAnimation {
  0%,
  50% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
.flip-top-enter-from {
  transform: rotateX(0deg);
}
.flip-top-enter-active {
  animation: flapTopAnimation var(--flap-animation-ease) var(--flap-animation-time);
}
.flip-top-enter-to {
  transform: rotateX(-90deg);
}
.flip-bottom-enter-from {
  transform: rotateX(90deg);
}
.flip-bottom-enter-active {
  animation: flapBottomAnimation var(--flap-animation-ease) var(--flap-animation-time);
}
.flip-bottom-enter-to {
  transform: rotateX(0deg);
}
</style>
