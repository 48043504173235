<script setup lang='ts'>

import type { ButtonHTMLAttributes } from 'vue'

defineProps<{
    type?: ButtonHTMLAttributes['type']
}>()

const emit = defineEmits<{
    click: []
}>()

</script>

<template>
    <button
        class='box-border rounded px-5 py-1.5 font-medium transition-colors duration-500 focus:outline-none'
        :type='type || "button"'
        @click='emit("click")'
    >
        <slot />
    </button>
</template>
