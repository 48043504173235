export type HallAssignmentStore = {
    candidatesPerAssignment: number
    seatAllocations: Array<[number, number]>
}

export const useHallAssignmentStore = defineStore('hallAssignment', {
    state: (): HallAssignmentStore => ({
        candidatesPerAssignment: 0,
        seatAllocations: []
    })
})
