<script setup lang='ts'>

// @ts-expect-error
import favicon from '@/assets/hkeaa_icon.svg'

const examStore = useExamStore().$state
const loginStore = useLoginStore()
const toastStore = useToastStore()

const dayjs = useDayjs()

// const { t } = useI18n()
const { $cs } = useNuxtApp()

const { start } = useAsyncInterval(() => Promise.all([
    // checkWrongSeating(),
    // checkPendingToiletRequests(),
    checkInvalidConfirmExamStatus(),
    checkMultiplePolls()
]))

useHead({
    link: [{
        rel: 'icon',
        type: 'image/svg+xml',
        href: favicon
    }],
    title: 'CS Portal by HKEAA|PESS2'
})

onMounted(() => start())

// async function checkWrongSeating() {
//     if (!examStore.hasData || !loginStore.accessToken) {
//         return
//     }

//     // candidate scanning wrong barcode warning
//     const records = examStore.centreType === 'CLASSROOM'
//         ? await $cs.candidateWrongSeating.readRecords.query(examStore.childSessions.map(s => s.exam_session_id))
//         : await $cs.candidateWrongSeating.readRecords.query([examStore.examSessionId])
//     const toasts = records.map(record => ([{
//         key: `wrong_seat_${record.candidate_no}_${record.affected_seat_no ?? ''}`,
//         message: {
//             key: 'toast.reminders.wrong_seat',
//             params: [record.candidate_no, record.affected_seat_no]
//         },
//         type: 'warn',
//         shouldBlock: false
//     }, {
//         key: `wrong_candidate_${record.candidate_no}`,
//         message: t('toast.reminders.wrong_candidate', [record.affected_seat_no]),
//         type: 'warn',
//         shouldBlock: false
//     }]) as const).flat()
//     const toastKeys = new Set(toasts.map(toast => toast.key as string))

//     Object.keys(toastStore.$state.toast).forEach(key => {
//         if (!toastKeys.has(key) && (key.startsWith('wrong_seat_') || key.startsWith('wrong_candidate_'))) {
//             toastStore.remove(key)
//         }
//     })

//     if (toasts.length > 0) {
//         toastStore.push(toasts as any)
//     }
// }

// async function checkPendingToiletRequests() {
//     if (!examStore.hasData || !loginStore.accessToken) {
//         return
//     }

//     // candidate toilet 15 minutes warning
//     const records = await $cs.sr4tForm.readForms.query(examStore.centreType === 'CLASSROOM'
//         ? examStore.childSessions.map(s => s.exam_session_id)
//         : [examStore.examSessionId]
//     )
//     const toasts = Object.keys(records)
//         .filter(key => records[key].actions.length > 0 && records[key].actions.slice(-1)[0].action === 'out' && (dayjs().diff(dayjs(records[key].actions.slice(-1)[0].recorded_at), 'minute') > 15))
//         .map(key => {
//             const seatNo = records[key].seat_no
//             const centreCode = examStore.centreType === 'CLASSROOM'
//                 ? examStore.childSessions.find(s => s.exam_session_id === records[key].exam_session_id)?.ctr_code
//                 : examStore.centreCode
//             return {
//                 key: `out_for_toilet_${seatNo}_${centreCode}`,
//                 message: {
//                     key: 'toast.reminders.out_for_toilet',
//                     params: [[seatNo], [centreCode]]
//                 },
//                 type: 'warn',
//                 shouldBlock: false
//             } as const
//         })
//     const toastKeys = new Set(toasts.map(toast => toast.key as string))
//     Object.keys(toastStore.$state.toast).forEach(key => {
//         if (!toastKeys.has(key) && key.startsWith('out_for_toilet_')) {
//             toastStore.remove(key)
//         }
//     })
//     if (toasts.length > 0) {
//         toastStore.push(toasts as any)
//     }
// }

async function checkInvalidConfirmExamStatus() {
    if (!examStore.hasData || !loginStore.accessToken) {
        return
    }

    const response = await $cs.header.readHallConfirmExamStatus
        .query({
            exam_session_id: examStore.examSessionId
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.error('checkInvalidConfirmExamStatus/readHallConfirmExamStatus', err)
        })

    if (!response) {
        return
    }

    if (response.status.confirmed === false && !!response.status.confirmed_at) {
        toastStore.push({
            key: 'exam-records-updated',
            type: 'warn',
            shouldBlock: false,
            message: {
                key: 'toast.warning.exam_records_updated_please_confirm',
                params: [[dayjs(response.status.confirmed_at).format('HH:mm:ss')]]
            }
        })
    }
    else {
        Object.keys(toastStore.$state.toast).forEach(key => {
            if (key === 'exam-records-updated') {
                toastStore.remove(key)
            }
        })
    }
}

async function checkMultiplePolls() {
    if (!examStore.hasData || !loginStore.accessToken || window?.location.pathname === '/soleQrCode') {
        return
    }

    await $cs.previousPolls.checkPreviousPoll.query({
        threshold: 19,
        examSession: { id: examStore.examSessionId },
        supervisor: { id: examStore.csId }
    })
}

</script>

<template>
    <div v-if='$route.path === "/soleQrCode"'>
        <NuxtPage
            v-if='examStore.hasData'
            :exam='examStore'
        />
    </div>
    <div
        v-else
        class='flex min-h-screen flex-col'
    >
        <div class='flex flex-col bg-white px-3'>
            <div class='flex justify-between'>
                <div class='my-2.5 mr-2 flex items-center gap-3'>
                    <HkeaaLogo />
                    <LocaleSwitcher />
                </div>

                <div
                    v-if='$route.path !== "/login" && examStore.hasData'
                    class='flex flex-row gap-6'
                >
                    <div
                        v-if='$route.path !== "/messageBoardOnlyExam"'
                        class='flex items-center gap-2'
                    >
                        <InvigilatorQrCode
                            v-if='examStore.centreType !== "CLASSROOM" && getIsPrimarySubject(examStore)'
                            :exam-date-utc='examStore.examDateUtc'
                        />
                    </div>

                    <ReportOptions
                        v-if='$route.path !== "/messageBoardOnlyExam"'
                        :centre-type='examStore.centreType'
                        :exam-session-id='examStore.examSessionId'
                        :exam-date-utc='examStore.examDateUtc'
                    />

                    <ExamConfirmation
                        v-if='$route.path !== "/messageBoardOnlyExam"'
                        :centre-type='examStore.centreType'
                        :cs-id='examStore.csId'
                        :exam-session-id='examStore.examSessionId'
                        :dual-subject-session-id='examStore.dualSubjectSessionId'
                        :scheduled-end-time='examStore.schedule.end'
                        :has-exam-expired='examStore.hasExamExpired'
                        :exam-date-utc='examStore.examDateUtc'
                    />

                    <LogoutButton
                        :centre-type='examStore.centreType'
                        :exam-session-id='examStore.examSessionId'
                        :supervisor-id='examStore.csId'
                        :ep-no='examStore.epNo'
                        :post='examStore.post'
                        :form-of-exam='examStore.formOfExam'
                    />
                </div>
            </div>
        </div>

        <NuxtPage v-if='$route.path === "/login"' />

        <div v-else-if='$route.path === "/messageBoardOnlyExam"'>
            <div v-if='examStore.hasData'>
                <NuxtPage :exam='examStore' />
            </div>
        </div>

        <div
            v-else
            class='grow bg-gray-100 py-4 lg:px-4 2xl:px-8'
        >
            <div
                v-if='examStore.hasData'
                class='flex flex-col justify-between gap-4 md:flex-row'
            >
                <ExamInformation
                    :exam-session-id='examStore.examSessionId'
                    :exam-year='examStore.examYear'
                    :centre='examStore.centre'
                    :centre-code='examStore.centreCode'
                    :subject='examStore.subject'
                    :subject-code='examStore.subjectCode'
                    :paper-code='examStore.paperCode'
                    :exam-date='examStore.examDate'
                    :centre-type='examStore.centreType'
                    :schedule='examStore.schedule'
                    :is-master='examStore.isMaster'
                />

                <HallTimer
                    :schedule='examStore.schedule'
                    :exam-session-id='examStore.examSessionId'
                    :dual-subject-session-id='examStore.dualSubjectSessionId'
                    :exam-date-utc='examStore.examDateUtc'
                    :paper-code='examStore.paperCode'
                    :supervisor-id='examStore.csId'
                />
            </div>

            <div
                class='mt-4'
                aria-hidden='true'
            />

            <div
                v-if='examStore.hasData'
                class='grid grid-cols-[auto,1fr]'
            >
                <SideMenu
                    class='sticky top-0 z-10 w-64'
                    :centre-type='examStore.centreType'
                    :centre-code='examStore.centreCode'
                    :is-master='examStore.isMaster'
                    :exam-date-utc='examStore.examDateUtc'
                    :is-secondary-subject='getIsSecondarySubject(examStore)'
                />

                <div class='overflow-hidden rounded-r-lg bg-white'>
                    <NuxtPage :exam='examStore' />
                </div>
            </div>
        </div>

        <div class='mt-auto flex items-center justify-between bg-black px-6 py-4 text-white'>
            <BuildVersion />
            <div>Copyright © HKEAA, All rights reserved</div>
        </div>

        <ClientOnly>
            <ToastDialog />
        </ClientOnly>
    </div>
</template>
