<script
    setup
    lang='ts'
    generic='
        T extends InputTypeHTMLAttribute,
        V extends T extends "number" ? number : string
    '
>

import type { HTMLAttributes, InputHTMLAttributes, InputTypeHTMLAttribute } from 'vue'

import { cn } from '~/lib/utils'

type Props = {
    value?: V | null
    type?: T
    attributes?: Omit<InputHTMLAttributes, 'type' | 'value' | 'class'>
    class?: HTMLAttributes['class']
    name?: string
    padStart?: Parameters<string['padStart']>
    disabledStyle?: 'solid' | 'outline'
    tooltip?: Array<string> | undefined
}

const { t } = useI18n()

const props = withDefaults(defineProps<Props>(), {
    value: undefined,
    type: undefined,
    attributes: undefined,
    class: undefined,
    name: Date.now().toString(36).slice(-8).padStart(8, '0'),
    padStart: undefined,
    disabledStyle: 'solid',
    tooltip: undefined
})

const emit = defineEmits<{
    input: [V]
    change: [V]
    blur: []
    keydown: [string]
    invalidate: [string]
}>()

defineExpose({
    getValue() {
        if (props.attributes?.disabled) {
            return props.value
        }

        return inputRef.value?.value
    },
    clear() {
        if (inputRef.value) {
            inputRef.value.value = ''
        }
    }
})

function handleInput(el: HTMLInputElement | HTMLTextAreaElement) {
    el.setCustomValidity('')

    if (props.type === 'number') {
        return emit('input', Number(el.value) as V)
    }

    emit('input', el.value as V)
}

function handleKeydown(e: KeyboardEvent) {
    emit('keydown', e.key)
}

function handleInvalidValue(el: HTMLInputElement | HTMLTextAreaElement) {
    const issue = resolveValidity(el.validity)

    if (!issue) {
        return
    }

    el.setCustomValidity(t(`validity.${issue}`, resolveI18nParam(el)))
}

function resolveValidity(validity: ValidityState) {
    if (validity.valid) {
        return ''
    }

    for (const key in validity) {
        const _key = key as keyof ValidityState

        if (_key === 'customError' || _key === 'valid') {
            continue
        }

        if (validity[_key]) {
            return _key
        }
    }

    return ''
}

function resolveI18nParam(el: HTMLInputElement | HTMLTextAreaElement) {
    if (el.validity.rangeUnderflow) {
        const min = el.getAttribute('min')
        return min ? [min] : []
    }

    // TODO: handle more cases

    return []
}

const inputRef = ref<HTMLInputElement>()
const paddedValue = computed(() => {
    if (props.type === 'number' && props.padStart !== undefined) {
        return (props.value?.toString() || '').padStart(...props.padStart)
    }

    return props.value
})

</script>

<template>
    <p
        v-if='attributes?.disabled'
        :id='`disabled-${name}-input`'
        :class='cn(
            "min-h-10 rounded px-4 py-2",
            [disabledStyle === "outline" ? "border-2 bg-gray-50" : "bg-gray-200"],
            props.class
        )'
    >
        {{ paddedValue }}
    </p>

    <ShadcnTooltipProvider
        v-else
        disable-closing-trigger
        :delay-duration='0'
    >
        <ShadcnTooltip :disabled='!tooltip'>
            <ShadcnTooltipTrigger as-child>
                <input
                    v-bind='attributes'
                    :id='`${name}-input`'
                    ref='inputRef'
                    :type='(type as InputTypeHTMLAttribute | undefined)'
                    :value='paddedValue'
                    :autocomplete='attributes?.autocomplete || "off"'
                    :class='cn(
                        "min-h-10 w-full rounded border-2 px-3 py-2 leading-tight tracking-wide text-gray-700 transition-colors focus:outline-none",
                        props.class
                    )'
                    @input='handleInput(($event.target as HTMLInputElement))'
                    @change='handleInput(($event.target as HTMLInputElement))'
                    @blur='emit("blur")'
                    @keydown='handleKeydown(($event as KeyboardEvent))'
                    @invalid='handleInvalidValue($event.target as HTMLInputElement)'
                >
            </ShadcnTooltipTrigger>
            <ShadcnTooltipContent
                :id='`${name}-tooltips`'
                as='div'
                class='bg-white'
                side='right'
            >
                <p
                    v-for='tooltip_ in tooltip'
                    :key='tooltip_'
                >
                    {{ tooltip_ }}
                </p>
            </ShadcnTooltipContent>
        </ShadcnTooltip>
    </ShadcnTooltipProvider>
</template>
