export class AnimationFrameInterval {
    handle: number | null = null
    callback: Function

    constructor(callback: Function) {
        this.callback = callback
    }

    private _move = () => !this.callback() ? this.stop() : this.start()

    start = () => {
        this.handle = requestAnimationFrame(this._move)
    }

    stop = () => {
        if (this.handle !== null) {
            cancelAnimationFrame(this.handle)
        }
    }
}

export const getRemainingDurationInSec = (startedTime: string|null, durationInMs: number, referenceTime: number) => {
    if (!startedTime) {
        return durationInMs / 1000
    }

    return Math.trunc((getExpectedEndedTimestamp(startedTime, durationInMs).getTime() - referenceTime) / 1000)
}

export const isReached = (duration: number) => duration <= 0

export const isFiveMinutes = (duration: number) => Math.trunc(duration) === 5 * 60

export const isTenMinutes = (duration: number) => Math.trunc(duration) === 10 * 60

export const isFifteenMinutes = (duration: number) => Math.trunc(duration) === 15 * 60

export const getGapInMinutes = (firstIso: string, secondIso: string) => {
    return Math.ceil((new Date(secondIso).getTime() - new Date(firstIso).getTime()) / 1000 / 60)
}

// timer clock needs the following functions
type Period = {
  hour: number,
  minute: number,
  second: number
}

export const getPeriodFrom = (duration: number) => { // duration in seconds
    if (!duration || duration <= 0 || duration >= 100 * 60 * 60) {
        return {
            hour: 0,
            minute: 0,
            second: 0
        }
    };
    const hour = Math.floor(duration / 3600)
    const minute = Math.floor((duration - hour * 3600) / 60)
    const second = duration - hour * 3600 - minute * 60
    return {
        hour,
        minute,
        second
    }
}

export const getTwoDigitHour = (period: Period) => (
    period.hour.toString().padStart(2, '0')
)

export const getTwoDigitMinute = (period: Period) => (
    period.minute.toString().padStart(2, '0')
)

export const getTwoDigitSecond = (period: Period) => (
    Math.trunc(period.second).toString().padStart(2, '0')
)
