<script setup lang='ts'>

defineProps<{
    examDateUtc: ExamStore['examDateUtc']
}>()

const router = useRouter()

const qrCodeWindow = ref<Window | null>(null)

function handleClickQrCode() {
    if (qrCodeWindow.value && !qrCodeWindow.value.closed) {
        qrCodeWindow.value.focus()
    }
    else {
        const routeData = router.resolve({ name: 'soleQrCode' })
        const width = 240
        const height = 240
        const left = window.screenX + (window.outerWidth - width) / 2
        const top = window.screenY + (window.outerHeight - height) / 2 - height * 0.8
        qrCodeWindow.value = window.open(routeData.href, '_blank', `height=${height},width=${width},left=${left},top=${top}`)
    }
}

</script>

<template>
    <div :title='$t("routes.qr_code_login")'>
        <button
            class='box-border flex size-12 items-center justify-center rounded py-1 pl-1 font-medium transition-colors duration-500 focus:outline-none md:w-60'
            :class='[isAfterToday(examDateUtc)
                ? "cursor-not-allowed bg-gray-200 text-gray-400 shadow-inner"
                : "bg-green-dark text-white hover:bg-green-700 focus:ring-2 focus:ring-green-dark focus:ring-offset-1"
            ]'
            type='button'
            :disabled='isAfterToday(examDateUtc)'
            @click='handleClickQrCode'
        >
            <div class='h-full'>
                <QrCode />
            </div>
            <div class='hidden pl-2 pr-1 text-left text-sm leading-4 md:block'>
                {{ $t('routes.qr_code_login') }}
            </div>
        </button>
    </div>
</template>
