<script setup lang='ts'>

import { computed, type HTMLAttributes } from 'vue'
import { TooltipContent, type TooltipContentEmits, type TooltipContentProps, TooltipPortal, useForwardPropsEmits } from 'radix-vue'

import { cn } from '~/lib/utils'

defineOptions({
    inheritAttrs: false
})

const props = withDefaults(defineProps<TooltipContentProps & { class?: HTMLAttributes['class'] }>(), {
    class: undefined,
    sideOffset: 4
})

const emits = defineEmits<TooltipContentEmits>()

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

</script>

<template>
    <TooltipPortal>
        <TooltipContent
            v-bind='{ ...forwarded, ...$attrs }'
            :class='cn("z-50 max-w-[420px] overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", props.class)'
        >
            <slot />
        </TooltipContent>
    </TooltipPortal>
</template>
