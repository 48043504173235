type LoginResponse = Exclude<Infer<'auth.createJwt'>, { shouldPrompt: true }>
type TrimmedLoginResponse = Omit<LoginResponse, 'accessToken' | 'shouldPrompt'>

type EmptyExamStore = { hasData: false }
export type ExamStore = { hasData: true } & TrimmedLoginResponse

export const useExamStore = defineStore('exam', {
    state: (): EmptyExamStore | ExamStore => ({ hasData: false }),
    actions: {
        registerExam(response: LoginResponse) {
            const { shouldPrompt: _0, accessToken: _1, ...examData } = response

            this.$reset()
            this.$patch({ hasData: true, ...examData })
        },
        $reset() {
            this.$patch(state => Object.assign(
                state,
                resetState(state)
            ))
        }
    },
    persist: {
        storage: persistedState.cookiesWithOptions({ sameSite: 'strict' })
    }
})

function resetState(state: EmptyExamStore | ExamStore) {
    return Object.fromEntries(
        Object.entries(state).map(([key]) => [
            key,
            key === 'hasData' ? false : undefined
        ])
    )
}
