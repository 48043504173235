type Input = Pick<ExamStore, 'examSessionId' | 'dualSubjectSessionId'>

export function getIsPrimarySubject({ examSessionId, dualSubjectSessionId }: Input) {
    if (!dualSubjectSessionId) {
        return true
    }

    return dualSubjectSessionId.primary === examSessionId
}

export function getIsSecondarySubject({ examSessionId, dualSubjectSessionId }: Input) {
    if (!dualSubjectSessionId) {
        return false
    }

    return dualSubjectSessionId.secondary === examSessionId
}
