<script setup lang='ts'>

type Props = {
    duration: number
}

const props = defineProps<Props>()

const getPeriod = () => {
    return getPeriodFrom(props.duration)
}
</script>

<template>
    <div class='flex items-center justify-center gap-x-4 xl:gap-x-10'>
        <div class='flex flex-col items-center'>
            <FlapDisplay
                :text='getTwoDigitHour(getPeriod())'
                class='mb-1'
            />
            <div class='text-base'>
                {{ $t('timer.hour_short') }}
            </div>
        </div>
        <div class='flex flex-col items-center'>
            <FlapDisplay
                :text='getTwoDigitMinute(getPeriod())'
                class='mb-1'
            />
            <div class='text-base'>
                {{ $t('timer.minute_short') }}
            </div>
        </div>
        <div class='flex flex-col items-center'>
            <FlapDisplay
                :text='getTwoDigitSecond(getPeriod())'
                class='mb-1'
            />
            <div class='text-base'>
                {{ $t('timer.second_short') }}
            </div>
        </div>
    </div>
</template>
