<script setup lang='ts'>

defineProps<{
    input: string | ToastI18nInput
}>()

const { t } = useI18n()

function getParams(inputs: Array<string | number | null | ToastI18nInput>) {
    return inputs.map(getParam).join('')
}

function getParam(input: string | number | null | ToastI18nInput): string {
    if (!isRecord(input)) {
        return `${input ?? ''}`
    }

    if (input.params?.length) {
        return t(input.key, input.params.map(p => p.map(getParam)))
    }

    return t(input.key)
}

</script>

<template>
    <p class='text-sm text-white'>
        {{ typeof input === 'string'
            ? input
            : input.params?.length
                ? $t(input.key, input.params.map(getParams))
                : $t(input.key) }}
    </p>
</template>
