import type { OpUnitType, QUnitType } from 'dayjs'

export default defineNuxtRouteMiddleware(async to => {
    const dayjs = useDayjs()
    const runtimeConfig = useRuntimeConfig()

    const { $cs } = useNuxtApp()

    const loginStore = useLoginStore()
    const hallAssignmentStore = useHallAssignmentStore()
    const examStore = useExamStore()

    if (loginStore.accessToken) {
        const [_, duration, unit] = /(\d+)(\w+)/.exec(runtimeConfig.public.VERIFY_TOKEN_VALIDITY) || ['', '', '']
        const minutesUntilNow = dayjs().diff(dayjs(loginStore.validatedAt), unit as QUnitType | OpUnitType)
        const shouldValidate = !duration || (minutesUntilNow >= Number(duration))

        if (shouldValidate) {
            const validJwt = await $cs.shared.readJwt
                .query()
                .catch(() => null)

            if (!validJwt) {
                loginStore.$reset()
                examStore.$reset()

                await navigateTo('/login')

                return hallAssignmentStore.$reset()
            }

            loginStore.validatedAt = new Date().toString()
        }

        if (!examStore.$state.hasData) {
            loginStore.$reset()

            await navigateTo('/login')

            return hallAssignmentStore.$reset()
        }

        if (examStore.$state.formOfExam?.includes('MB') && !/^\/(messageBoardOnlyExam)?$/.test(to.path)) {
            return navigateTo('/messageBoardOnlyExam')
        }

        const isSecondarySubject = examStore.$state.dualSubjectSessionId?.secondary === examStore.$state.examSessionId

        if (/^\/(login)?$/.test(to.path)) {
            return navigateTo(isSecondarySubject ? '/attendance' : '/assignee')
        }

        if (isSecondarySubject && /^\/assign(ee|ment)$/.test(to.path)) {
            return navigateTo('/attendance')
        }
    }
    else if (to.path !== '/login') {
        return navigateTo('/login')
    }
})
