import { createTRPCNuxtClient, getQueryKey, httpBatchLink } from 'trpc-nuxt/client'

import { useCustomHeaders } from '../composables/useCustomHeaders'

import type { CandidateRouter } from '../server/trpc/candidate/routers'
import type { CentreSupervisorRouter } from '../server/trpc/centreSupervisor/routers'
import type { SupportPortalRouter } from '../server/trpc/supportPortal/routers'

export default defineNuxtPlugin(() => {
    const { customHeaders } = useCustomHeaders()
    const trpcHeaders = useRequestHeaders()

    const clientOpts = {
        links: [
            httpBatchLink({
                url: '/api/trpc',
                headers: () => ({
                    ...customHeaders.value,
                    ...trpcHeaders
                })
            })
        ]
    }

    const provide = {
        /** Candidate mobile app router */
        cn: createTRPCNuxtClient<CandidateRouter>(clientOpts),
        /** Centre Supervisor web app router */
        cs: createTRPCNuxtClient<CentreSupervisorRouter>(clientOpts),
        /** Support Portal web app router */
        sp: createTRPCNuxtClient<SupportPortalRouter>(clientOpts),

        getQueryKey
    }

    return { provide }
})
