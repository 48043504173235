<script setup lang='ts'>

</script>

<template>
    <img
        class='hidden h-14 border-r-4 border-r-slate-300 object-scale-down pr-4 xl:block'
        src='@/assets/hkeaa_logo.svg'
        alt='HKEAA Logo'
        @click='navigateTo("/")'
    >
    <img
        class='h-14 border-r-4 border-r-slate-300 object-scale-down pr-4 xl:hidden'
        src='@/assets/hkeaa_icon.svg'
        alt='HKEAA Logo'
        @click='navigateTo("/")'
    >
    <div class='hidden select-none flex-col text-gray-600 xl:flex'>
        <span class='text-sm tracking-tight'>Public Examinations Support System 2 (PESS2)</span>
        <span class='text-lg tracking-widest'>第二代公開考試支援系統</span>
    </div>
</template>
