type LoginStore = {
    accessToken: string | undefined
    validatedAt: string | undefined
}

export const useLoginStore = defineStore('login', {
    state: (): LoginStore => ({
        accessToken: undefined,
        validatedAt: undefined
    }),
    actions: {
        getCookiesToken() {
            return persistedState.cookies.getItem('login')?.includes('accessToken')
        }
    },
    persist: {
        storage: persistedState.cookiesWithOptions({ sameSite: 'strict' })
    }
})
