<script setup lang='ts'>

const props = defineProps<{
    centreType: ExamStore['centreType']
    centreCode: ExamStore['centreCode']
    isMaster: ExamStore['isMaster']
    examDateUtc: ExamStore['examDateUtc']
    isSecondarySubject: boolean
}>()

const { $cs } = useNuxtApp()
const { data: unreadCount, refresh } = $cs.messageBoard.getUnread.useQuery({
    ctr_code: props.centreCode
}, {
    queryKey: `messageBoard.getUnread-${props.centreCode}`
})

const { start: pollMessageBoardUnread } = useAsyncInterval(() => refresh())

const showSubMenu = ref<string>()

onMounted(pollMessageBoardUnread)

async function openMessageBoard() {
    const { authUrl } = await $cs.messageBoard.getLoginKey.query({
        ctr_code: props.centreCode
    })

    if (!authUrl) {
        // TODO: show error message
        // toastStore.push({
        //     key: '',
        //     type: 'error',
        //     message: { key: 'toast.error.message_board_login_failed' }
        // })
        return
    }

    window.open(authUrl, '_blank')
}

</script>

<template>
    <MenuItemGroup>
        <MenuItem
            v-if='!isSecondarySubject'
            class='relative rounded-l-lg'
            :highlight='/\/assign(ee|ment)$/.test($route.path)'
            :dim='isAfterToday(examDateUtc)'
            @mouseenter='showSubMenu = "assignment"'
            @mouseleave='showSubMenu = undefined'
        >
            <ScanningIcon class='size-8 text-white' />
            {{ $t('routes.invigilator_login_qr_code_and_checkin_status') }}
            <ChevronRight />

            <MenuItemGroup
                v-if='showSubMenu === "assignment"'
                class='absolute left-64 w-64 place-self-start overflow-hidden rounded-r-lg bg-zinc-200 [&]:gap-1'
            >
                <MenuItem
                    :highlight='/\/assignee$/.test($route.path)'
                    :dim='isAfterToday(examDateUtc)'
                    @click='navigateTo("/assignee")'
                >
                    <span class='col-span-3'>
                        {{ $t('routes.qr_code_login') }}
                    </span>
                </MenuItem>

                <MenuItem
                    :highlight='/\/assignment$/.test($route.path)'
                    @click='navigateTo("/assignment")'
                >
                    <span class='col-span-3'>
                        {{ $t('routes.invigilator_assignment_and_checkin_status') }}
                    </span>
                </MenuItem>
            </MenuItemGroup>
        </MenuItem>

        <MenuItem
            class='rounded-l-lg'
            :highlight='/\/attendance$/.test($route.path)'
            @click='navigateTo("/attendance")'
        >
            <AttendanceIcon class='size-8 text-white' />
            <span class='col-span-2'>
                {{ $t('routes.attendance_and_script_records') }}
            </span>
        </MenuItem>

        <MenuItem
            class='rounded-l-lg'
            :highlight='/^\/specialCases/.test($route.path)'
            :dim='isAfterToday(examDateUtc)'
            @click='navigateTo("/specialCases")'
        >
            <SpecialCaseIcon class='size-8 text-white' />
            <span class='col-span-2'>
                {{ $t(`routes.${isMaster ? 'wrong_centre_or_version_and_special_room' : 'wrong_centre_or_version'}`) }}
            </span>
        </MenuItem>

        <!-- <MenuItem
            class='relative rounded-l-lg'
            :highlight='/\/specialCases|rejectRemarks|sr4t$/.test($route.path)'
            :dim='isAfterToday(examDateUtc)'
            @mouseenter='showSubMenu = "specialCases"'
            @mouseleave='showSubMenu = undefined'
        >
            <SpecialCaseIcon class='size-8 text-white' />
            {{ $t('routes.special_case_and_irregularities') }}
            <ChevronRight />

            <MenuItemGroup
                v-if='showSubMenu === "specialCases"'
                class='absolute left-64 w-64 place-self-start overflow-hidden rounded-r-lg bg-zinc-200 [&]:gap-1'
            >
                <MenuItem
                    :highlight='/^\/specialCases/.test($route.path)'
                    :dim='isAfterToday(examDateUtc)'
                    @click='navigateTo("/specialCases")'
                >
                    <span class='col-span-3'>
                        {{ $t(`routes.${isMaster ? 'wrong_centre_or_version_and_special_room' : 'wrong_centre_or_version'}`) }}
                    </span>
                </MenuItem>

                <MenuItem
                    :highlight='/^\/rejectRemarks/.test($route.path)'
                    :dim='isAfterToday(examDateUtc)'
                    @click='navigateTo("/rejectRemarks")'
                >
                    <span class='col-span-3'>
                        {{ $t('routes.irregularities') }}
                    </span>
                </MenuItem>
            </MenuItemGroup>
        </MenuItem> -->

        <MenuItem
            class='relative rounded-l-lg'
            :highlight='/^\/(sessionalReports|(discrepancy|(centre|specialRoom)Summary)Report)/.test($route.path)'
            :dim='isAfterToday(examDateUtc)'
            @mouseenter='showSubMenu = "report"'
            @mouseleave='showSubMenu = undefined'
        >
            <ReportIcon class='size-8 text-white' />
            {{ $t('routes.report') }}
            <ChevronRight />

            <MenuItemGroup
                v-if='showSubMenu === "report"'
                class='absolute left-64 w-64 place-self-start overflow-hidden rounded-r-lg bg-zinc-200 [&]:gap-1'
            >
                <!-- <MenuItem
                    :highlight='/^\/sessionalReports/.test($route.path)'
                    :dim='isAfterToday(examDateUtc)'
                    @click='navigateTo("/sessionalReports")'
                >
                    <span class='col-span-3'>
                        {{ $t('routes.sessional_report') }}
                    </span>
                </MenuItem> -->

                <MenuItem
                    :highlight='/\/discrepancyReport/.test($route.path)'
                    :dim='isAfterToday(examDateUtc)'
                    @click='navigateTo("/discrepancyReports")'
                >
                    <span class='col-span-3'>
                        {{ $t('routes.discrepancy_report') }}
                    </span>
                </MenuItem>

                <MenuItem
                    :highlight='/\/(centre|specialRoom)SummaryReport$/.test($route.path)'
                    :dim='isAfterToday(examDateUtc)'
                    @click='navigateTo("/centreSummaryReport")'
                >
                    <span class='col-span-3'>
                        {{ $t('routes.summary_report') }}
                    </span>
                </MenuItem>
            </MenuItemGroup>
        </MenuItem>

        <div class='overflow-hidden rounded-l-lg bg-zinc-400'>
            <MenuItem :dim='isAfterToday(examDateUtc)'>
                <ChatIcon class='size-8 text-white' />
                <span class='col-span-2'>
                    {{ $t('routes.message_board') }} {{ unreadCount?.count && unreadCount.count > 0 ? `(${unreadCount.count})` : '' }}
                </span>
            </MenuItem>

            <div class='col-span-3 grid gap-4 p-2'>
                <GreenSolidButton
                    class='text-left'
                    :disabled='isAfterToday(examDateUtc)'
                    @click='openMessageBoard'
                >
                    {{ $t('routes.communication_with_hkeaa') }}
                </GreenSolidButton>

                <!-- <GreenSolidButton
                    :disabled='isAfterToday(examDateUtc)'
                    class='text-left'
                    @click='navigateTo("/messageBoardGroupManagement")'
                >
                    {{ $t('routes.create_invigilators_chat_group') }}
                </GreenSolidButton> -->
            </div>
        </div>
    </MenuItemGroup>
</template>
