<template>
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlns:xlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 46 46'
        stroke='currentColor'
        fill='currentColor'
    >
        <defs>
            <clipPath id='clippath'>
                <rect
                    x='2.18'
                    y='1.68'
                    width='41.65'
                    height='42.65'
                />
            </clipPath>
            <clipPath id='clippath-1'>
                <rect
                    x='2.18'
                    y='1.68'
                    width='41.65'
                    height='42.65'
                />
            </clipPath>
            <clipPath id='clippath-2'>
                <rect
                    x='9.9'
                    y='22.76'
                    width='17.21'
                    height='6.15'
                />
            </clipPath>
        </defs>
        <g>
            <g
                id='Group_19647'
                data-name='Group 19647'
            >
                <g
                    id='Group_19646'
                    data-name='Group 19646'
                >
                    <g>
                        <g
                            id='Group_19645'
                            data-name='Group 19645'
                        >
                            <path
                                id='Path_19820'
                                data-name='Path 19820'
                                d='m37.97,1.68h-20.94c-3.23,0-5.86,2.62-5.86,5.86v4.02c0,.8.65,1.45,1.45,1.45s1.45-.65,1.45-1.45v-4.02c0-1.64,1.33-2.96,2.96-2.96h20.93c1.64,0,2.96,1.33,2.96,2.96v18.99l-4.91-4.91c-.35-.34-.75-.61-1.19-.81v3.72l6.53,6.53c.14.13.3.24.47.31h.02c.34.14.72.14,1.06,0h.02c.36-.15.64-.44.79-.8v-.02c.07-.17.11-.35.11-.53V7.53c0-3.23-2.62-5.85-5.85-5.86'
                            />
                            <path
                                id='Path_19821'
                                data-name='Path 19821'
                                d='m28.97,14.53H8.03c-3.23,0-5.86,2.62-5.86,5.86v22.49c0,.18.03.36.1.53v.02c.15.36.44.64.79.79h.02c.34.14.72.14,1.06,0h.03c.18-.08.34-.19.47-.32l7.73-7.73s.07,0,.1,0h16.49c3.23,0,5.86-2.62,5.86-5.86v-9.93c0-3.23-2.62-5.86-5.86-5.86m-18.98,19.94l-4.91,4.91v-18.99c0-1.64,1.33-2.96,2.96-2.96h20.94c1.64,0,2.96,1.33,2.96,2.96v9.93c0,1.64-1.33,2.96-2.96,2.96H12.84c-.24,0-.48.02-.71.06-.81.15-1.56.54-2.14,1.12'
                            />
                            <g
                                id='Group_19644'
                                data-name='Group 19644'
                            >
                                <g
                                    id='Group_19643'
                                    data-name='Group 19643'
                                >
                                    <g>
                                        <g
                                            id='Group_19642'
                                            data-name='Group 19642'
                                        >
                                            <path
                                                id='Path_19822'
                                                data-name='Path 19822'
                                                d='m13.03,24.26c-.89-.01-1.62.7-1.63,1.59,0,0,0,0,0,.01.04.87.76,1.55,1.62,1.55.89,0,1.63-.69,1.67-1.58-.03-.89-.77-1.6-1.66-1.58Z'
                                            />
                                            <path
                                                id='Path_19823'
                                                data-name='Path 19823'
                                                d='m18.53,24.26c-.88-.02-1.61.67-1.63,1.55,0,.01,0,.02,0,.03.03.87.74,1.56,1.61,1.57.89.02,1.63-.68,1.67-1.57-.02-.89-.75-1.6-1.64-1.59Z'
                                            />
                                            <path
                                                id='Path_19824'
                                                data-name='Path 19824'
                                                d='m23.96,24.26c-.88-.02-1.61.67-1.63,1.55,0,.01,0,.02,0,.03.03.87.74,1.56,1.61,1.57.89.02,1.63-.68,1.67-1.57-.02-.89-.75-1.6-1.65-1.59Z'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
