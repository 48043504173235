export function useAsyncInterval<T>(callback: () => Promise<T>, interval = 20000) {
    const { t } = useI18n()
    const { handleServerError } = useServerErrorHandler(t)

    const pending = ref(false)
    const nextTimeout = ref<NodeJS.Timeout | null>(null)

    const start = () => {
        if (pending.value) {
            return
        }

        pending.value = true
        resolveCallback()
    }

    const resolveCallback = async () => {
        while (pending.value) {
            await callback().catch(handleServerError)
            await wait()
        }
    }

    const wait = () => new Promise<void>(resolve => {
        nextTimeout.value = setTimeout(() => resolve(), interval)
    })

    const stop = () => {
        pending.value = false

        if (nextTimeout.value) {
            clearTimeout(nextTimeout.value)
            nextTimeout.value = null
        }
    }

    onUnmounted(() => stop())

    return {
        start,
        stop,
        pending,
        nextTimeout
    }
}
