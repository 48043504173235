export const useCustomHeaders = () => {
    const headers = useState('customHeaders', (): Record<string, string> => ({}))

    const customHeaders = computed(() => headers.value)

    const getCustomHeader = (key: string) => headers.value[key]

    const setCustomHeader = (key: string, value: string) => {
        headers.value[key] = value
    }

    const removeCustomHeader = (key: string) => {
        if (key in headers.value) {
            delete headers.value[key]
        }
    }

    return {
        customHeaders,
        getCustomHeader,
        setCustomHeader,
        removeCustomHeader
    }
}
