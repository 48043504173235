<template>
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlns:xlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 46 46'
        stroke='currentColor'
        fill='currentColor'
    >
        <defs>
            <clipPath id='clippath'>
                <rect
                    x='2.18'
                    y='1.68'
                    width='41.65'
                    height='42.65'
                />
            </clipPath>
            <clipPath id='clippath-1'>
                <rect
                    x='2.18'
                    y='1.68'
                    width='41.65'
                    height='42.65'
                />
            </clipPath>
            <clipPath id='clippath-2'>
                <rect
                    x='6.8'
                    y='7.68'
                    width='16.5'
                    height='3'
                />
            </clipPath>
            <clipPath id='clippath-3'>
                <rect
                    x='6.8'
                    y='12.68'
                    width='16.5'
                    height='3'
                />
            </clipPath>
            <clipPath id='clippath-4'>
                <rect
                    x='6.8'
                    y='30.68'
                    width='16.5'
                    height='3'
                />
            </clipPath>
            <clipPath id='clippath-5'>
                <rect
                    x='6.8'
                    y='35.68'
                    width='16.5'
                    height='3'
                />
            </clipPath>
        </defs>
        <g
            id='Group_19620'
            data-name='Group 19620'
        >
            <g
                id='Group_19619'
                data-name='Group 19619'
            >
                <g
                    id='Group_19618'
                    data-name='Group 19618'
                >
                    <g
                        id='Group_19608'
                        data-name='Group 19608'
                    >
                        <g
                            id='Group_19607'
                            data-name='Group 19607'
                        >
                            <g
                                id='Group_19606'
                                data-name='Group 19606'
                            >
                                <line
                                    id='Line_1122'
                                    data-name='Line 1122'
                                    x1='21.8'
                                    y1='9.18'
                                    x2='8.3'
                                    y2='9.18'
                                />
                            </g>
                        </g>
                    </g>
                    <g
                        id='Group_19611'
                        data-name='Group 19611'
                    >
                        <g
                            id='Group_19610'
                            data-name='Group 19610'
                        >
                            <g
                                id='Group_19609'
                                data-name='Group 19609'
                            >
                                <line
                                    id='Line_1123'
                                    data-name='Line 1123'
                                    x1='21.8'
                                    y1='14.18'
                                    x2='8.3'
                                    y2='14.18'
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        id='Path_19811'
                        data-name='Path 19811'
                        d='m37.97,21.2H8.03c-3.16,0-5.73-2.57-5.73-5.73v-7.94c0-3.16,2.57-5.73,5.73-5.73h29.94c3.16,0,5.73,2.57,5.73,5.73v7.94c0,3.16-2.57,5.73-5.73,5.73M8.03,4.45c-1.7,0-3.08,1.38-3.09,3.09v7.94c0,1.7,1.38,3.08,3.09,3.09h29.94c1.7,0,3.08-1.38,3.09-3.09v-7.94c0-1.7-1.38-3.08-3.09-3.09H8.03Z'
                    />
                    <path
                        id='Path_19812'
                        data-name='Path 19812'
                        d='m37.97,21.32H8.03c-3.23,0-5.85-2.62-5.86-5.86v-7.94c0-3.23,2.62-5.85,5.86-5.86h29.94c3.23,0,5.85,2.62,5.86,5.86v7.94c0,3.23-2.62,5.85-5.86,5.86M8.03,1.93c-3.09,0-5.6,2.51-5.61,5.61v7.94c0,3.1,2.51,5.61,5.61,5.61h29.94c3.1,0,5.61-2.51,5.61-5.61v-7.94c0-3.1-2.51-5.6-5.61-5.61H8.03Zm29.94,16.75H8.03c-1.77,0-3.21-1.44-3.21-3.21v-7.93c0-1.77,1.44-3.21,3.21-3.21h29.94c1.77,0,3.21,1.44,3.21,3.21v7.94c0,1.77-1.44,3.2-3.21,3.21M8.03,4.57c-1.63,0-2.96,1.33-2.96,2.96v7.94c0,1.63,1.33,2.96,2.96,2.96h29.94c1.63,0,2.96-1.33,2.96-2.96v-7.94c0-1.63-1.33-2.96-2.96-2.96H8.03Z'
                    />
                    <path
                        id='Path_19813'
                        data-name='Path 19813'
                        d='m28.2,12.24l1.55,1.55c.34.34.88.34,1.22,0l4.83-4.83'
                    />
                    <g
                        id='Group_19614'
                        data-name='Group 19614'
                    >
                        <g
                            id='Group_19613'
                            data-name='Group 19613'
                        >
                            <g
                                id='Group_19612'
                                data-name='Group 19612'
                            >
                                <line
                                    id='Line_1124'
                                    data-name='Line 1124'
                                    x1='21.8'
                                    y1='32.18'
                                    x2='8.3'
                                    y2='32.18'
                                />
                            </g>
                        </g>
                    </g>
                    <g
                        id='Group_19617'
                        data-name='Group 19617'
                    >
                        <g
                            id='Group_19616'
                            data-name='Group 19616'
                        >
                            <g
                                id='Group_19615'
                                data-name='Group 19615'
                            >
                                <line
                                    id='Line_1125'
                                    data-name='Line 1125'
                                    x1='21.8'
                                    y1='37.18'
                                    x2='8.3'
                                    y2='37.18'
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        id='Path_19814'
                        data-name='Path 19814'
                        d='m37.97,44.2H8.03c-3.16,0-5.73-2.57-5.73-5.73v-7.93c0-3.16,2.57-5.73,5.73-5.73h29.94c3.16,0,5.73,2.57,5.73,5.73v7.94c0,3.16-2.57,5.73-5.73,5.73M8.03,27.45c-1.7,0-3.08,1.38-3.09,3.09v7.93c0,1.7,1.38,3.08,3.09,3.09h29.94c1.7,0,3.08-1.38,3.09-3.09v-7.93c0-1.7-1.38-3.08-3.09-3.09H8.03Z'
                    />
                    <path
                        id='Path_19815'
                        data-name='Path 19815'
                        d='m37.97,44.32H8.03c-3.23,0-5.85-2.62-5.86-5.86v-7.93c0-3.23,2.62-5.85,5.86-5.86h29.94c3.23,0,5.85,2.62,5.86,5.86v7.94c0,3.23-2.62,5.85-5.86,5.86M8.03,24.93c-3.09,0-5.6,2.51-5.61,5.61v7.93c0,3.1,2.51,5.61,5.61,5.61h29.94c3.1,0,5.61-2.51,5.61-5.61v-7.93c0-3.09-2.51-5.6-5.61-5.61H8.03Zm29.94,16.75H8.03c-1.77,0-3.21-1.44-3.21-3.21v-7.93c0-1.77,1.44-3.21,3.21-3.21h29.94c1.77,0,3.21,1.44,3.21,3.21v7.93c0,1.77-1.44,3.2-3.21,3.21m-29.94-14.1c-1.63,0-2.96,1.33-2.96,2.96v7.94c0,1.63,1.33,2.96,2.96,2.96h29.94c1.63,0,2.96-1.33,2.96-2.96v-7.94c0-1.63-1.33-2.95-2.96-2.96H8.03Z'
                    />
                </g>
            </g>
        </g>
    </svg>
</template>
