export function isAfterToday(dateString?: number | string | Date | null) {
    if (dateString === undefined || dateString === null) {
        return true
    }

    const date = new Date(dateString)

    if (Object.prototype.toString.call(date) !== '[object Date]' || isNaN(date.getTime())) {
        return true
    }

    const today = new Date()

    date.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)

    return today < date
}
