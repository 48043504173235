import type { UnionKeys } from './types'

export function hasKey<
    R extends object,
    K extends UnionKeys<R>,
    ER extends Extract<R, Record<K, any>>
>(
    record: R,
    key: K
): record is { [k in keyof ER]: ER[k] } & { [k in K]: ER[k] } {
    return key in record
}

export function hasKeys<
    R extends object,
    K extends UnionKeys<R>,
    ER extends Extract<R, Record<K, any>>
>(
    record: R,
    keys: Array<K>
): record is { [k in keyof ER]: ER[k] } & { [k in K]: ER[k] } {
    return keys.every(key => key in record)
}
