import revive_payload_client_EK87kyaFnK from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_drizzle-orm@0.33.0_@types+pg@8.11.10_p_k5w7osga66zpeaj2oyslr55xmm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_a6bzI0gZxF from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_drizzle-orm@0.33.0_@types+pg@8.11.10_p_k5w7osga66zpeaj2oyslr55xmm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_b6Uvg1HvN2 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_drizzle-orm@0.33.0_@types+pg@8.11.10_p_k5w7osga66zpeaj2oyslr55xmm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_y9M4EfvHyy from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_drizzle-orm@0.33.0_@types+pg@8.11.10_p_k5w7osga66zpeaj2oyslr55xmm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_u1aGrh12TC from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_drizzle-orm@0.33.0_@types+pg@8.11.10_p_k5w7osga66zpeaj2oyslr55xmm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_rsExRdYNXR from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_drizzle-orm@0.33.0_@types+pg@8.11.10_p_k5w7osga66zpeaj2oyslr55xmm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_HGjgOdD0pK from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_drizzle-orm@0.33.0_@types+pg@8.11.10_p_k5w7osga66zpeaj2oyslr55xmm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_pfZLgywtdQ from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/centre-supervisor-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_KQilILkFUH from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_drizzle-orm@0.33.0_@types+pg@8.11.10_p_k5w7osga66zpeaj2oyslr55xmm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_oBpFtQ72gP from "/app/centre-supervisor-nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.26.0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/app/centre-supervisor-nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_wHwcwjRICt from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@primevue+nuxt-module@4.2.1_patch_hash=y2bjtiada6cj3qvvllbuctyaka_@babel+parser@7.26.2_magica_aefaqvi5wf5iftn4tvdy567uti/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5z1A7s7RRW from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_QmE8MFJsZv from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_niomL1ltso from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typesc_pityk3jbljlssgrxa3qiahq4ji/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import trpc_IJvhwZlfLl from "/app/backend/plugins/trpc.ts";
import chartjs_1wC9lAM0Tp from "/app/centre-supervisor-nuxt/plugins/chartjs.ts";
import hookVueError_FUSNmNXceB from "/app/centre-supervisor-nuxt/plugins/hookVueError.ts";
export default [
  revive_payload_client_EK87kyaFnK,
  unhead_a6bzI0gZxF,
  router_b6Uvg1HvN2,
  payload_client_y9M4EfvHyy,
  navigation_repaint_client_u1aGrh12TC,
  check_outdated_build_client_rsExRdYNXR,
  chunk_reload_client_HGjgOdD0pK,
  plugin_vue3_pfZLgywtdQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KQilILkFUH,
  plugin_oBpFtQ72gP,
  primevue_plugin_egKpok8Auk,
  plugin_client_wHwcwjRICt,
  switch_locale_path_ssr_5z1A7s7RRW,
  i18n_QmE8MFJsZv,
  plugin_niomL1ltso,
  trpc_IJvhwZlfLl,
  chartjs_1wC9lAM0Tp,
  hookVueError_FUSNmNXceB
]