<script setup lang='ts'>

import type { HTMLAttributes } from 'vue'

import { cn } from '~/lib/utils'

interface SkeletonProps {
    class?: HTMLAttributes['class']
    count?: number
}

const props = defineProps<SkeletonProps>()

</script>

<template>
    <template v-if='count && count > 1'>
        <div
            v-for='n of count'
            :key='n'
            :class='cn("animate-pulse rounded-md bg-black/15", props.class)'
        />
    </template>
    <div
        v-else
        :class='cn("animate-pulse rounded-md bg-black/15", props.class)'
    >
        <slot />
    </div>
</template>
