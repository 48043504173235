<script setup lang='ts'>

defineProps<{
    toast: Toast<ToastType>
}>()

const emit = defineEmits<{
    remove: []
}>()

</script>

<template>
    <div
        :class='{
            "bg-pink-600": toast.type === "error",
            "bg-cyan-600": toast.type === "warn",
            "bg-green-light": toast.type === "info",
        }'
        class='grid grid-cols-[min-content,1fr,min-content] items-center gap-2 p-3'
    >
        <SolidInformationCircle
            v-if='toast.type === "info"'
            class='text-white'
        />

        <SolidExclamationCircle
            v-else-if='toast.type === "warn"'
            class='text-white'
        />

        <SolidExclamationTriangle
            v-else-if='toast.type === "error"'
            class='text-white'
        />

        <div
            v-if='Array.isArray(toast.message)'
            class='grid gap-2'
        >
            <ToastI18nText
                v-for='(message, i) in toast.message'
                :key='`${toast.key}-${i}`'
                :input='message'
            />
        </div>

        <ToastI18nText
            v-else
            :input='toast.message'
        />

        <button
            class='opacity-50 outline-0 transition-opacity hover:opacity-100'
            type='reset'
            @click='emit("remove")'
        >
            <XMark class='size-4 text-white' />
        </button>
    </div>
</template>
