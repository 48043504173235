<template>
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlns:xlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 46 46'
        stroke='currentColor'
        fill='currentColor'
    >
        <defs>
            <clipPath id='clippath'>
                <rect
                    x='.5'
                    y='2.5'
                    width='45'
                    height='41'
                />
            </clipPath>
            <clipPath id='clippath-1'>
                <rect
                    x='.5'
                    y='2.5'
                    width='45'
                    height='41'
                />
            </clipPath>
            <clipPath id='clippath-2'>
                <rect
                    x='.5'
                    y='21.5'
                    width='45'
                    height='3'
                />
            </clipPath>
        </defs>
        <g>
            <g
                id='Group_19597'
                data-name='Group 19597'
            >
                <g
                    id='Group_19596'
                    data-name='Group 19596'
                >
                    <g>
                        <g
                            id='Group_19595'
                            data-name='Group 19595'
                        >
                            <path
                                id='Path_19799'
                                data-name='Path 19799'
                                d='m15.74,2.5h-6.28c-3.96,0-7.17,3.21-7.17,7.18v6.28c.07.83.79,1.44,1.62,1.37.73-.06,1.31-.64,1.37-1.37v-6.38c.04-1.06.49-2.06,1.25-2.8.74-.78,1.75-1.25,2.83-1.28h6.38c.83-.07,1.44-.79,1.37-1.62-.06-.73-.64-1.31-1.37-1.37h0Z'
                            />
                            <path
                                id='Path_19800'
                                data-name='Path 19800'
                                d='m36.64,2.5h-6.38c-.83.07-1.44.79-1.37,1.62.06.73.64,1.31,1.37,1.37h6.38c1.08.04,2.09.5,2.83,1.28.76.74,1.21,1.74,1.25,2.8v6.38c0,.83.67,1.5,1.49,1.5s1.5-.67,1.5-1.5v-6.38c0-3.9-3.17-7.07-7.07-7.07'
                            />
                            <path
                                id='Path_19801'
                                data-name='Path 19801'
                                d='m15.74,40.51h-6.38c-1.08-.04-2.09-.5-2.83-1.28-.76-.74-1.21-1.74-1.25-2.8v-6.37c-.07-.83-.79-1.44-1.62-1.37-.73.06-1.31.64-1.37,1.37v6.38c0,3.9,3.17,7.06,7.07,7.07h6.38c.83-.07,1.44-.79,1.37-1.62-.06-.73-.64-1.31-1.37-1.37h0Z'
                            />
                            <path
                                id='Path_19802'
                                data-name='Path 19802'
                                d='m42.21,28.55c-.82,0-1.49.67-1.49,1.49v6.38c-.13,2.19-1.88,3.94-4.08,4.08h-6.38c-.83-.07-1.55.55-1.62,1.37s.55,1.55,1.37,1.62c.08,0,.16,0,.24,0h6.27c3.96,0,7.17-3.21,7.17-7.17v-6.27c0-.83-.67-1.5-1.49-1.5'
                            />
                            <g
                                id='Group_19594'
                                data-name='Group 19594'
                            >
                                <g
                                    id='Group_19593'
                                    data-name='Group 19593'
                                >
                                    <g>
                                        <g
                                            id='Group_19592'
                                            data-name='Group 19592'
                                        >
                                            <path
                                                id='Path_19803'
                                                data-name='Path 19803'
                                                d='m44,24.5H2c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h42c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
