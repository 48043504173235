<script setup lang='ts'>

const props = defineProps<{
   show: boolean
   fadeOnClickOutside?: boolean
   static?: boolean
   dim?: number
}>()

const isFadingOut = ref(false)

const emit = defineEmits<{
    close: []
    clickOutside: []
}>()

function delayedClose(callback: () => void) {
    if (props.static) {
        return
    }

    fadeOut(callback)
}

function fadeOut(callback: () => void) {
    if (isFadingOut.value) {
        return
    }

    isFadingOut.value = true

    setTimeout(() => {
        callback()
        isFadingOut.value = false
    }, 200)
}

</script>

<template>
    <HeadlessTransitionRoot
        appear
        as='template'
        :show='show && !isFadingOut'
    >
        <HeadlessDialog
            as='div'
            class='relative z-[1000]'
            @close='delayedClose(() => emit("close"))'
        >
            <HeadlessTransitionChild
                as='template'
                enter='duration-300 ease-out'
                enter-from='opacity-0'
                enter-to='opacity-100'
                leave='duration-200 ease-in'
                leave-from='opacity-100'
                leave-to='opacity-0'
            >
                <div
                    class='fixed inset-0 bg-black'
                    :style='{ opacity: dim || 0.25 }'
                />
            </HeadlessTransitionChild>

            <div
                class='fixed inset-0 overflow-y-auto'
                @pointerdown='fadeOnClickOutside && fadeOut(() => emit("clickOutside"))'
            >
                <div class='flex h-full items-center justify-center p-4 text-center'>
                    <HeadlessTransitionChild
                        as='template'
                        enter='duration-300 ease-out'
                        enter-from='opacity-0 scale-95'
                        enter-to='opacity-100 scale-100'
                        leave='duration-200 ease-in'
                        leave-from='opacity-100 scale-100'
                        leave-to='opacity-0 scale-95'
                    >
                        <slot />
                    </HeadlessTransitionChild>
                </div>
            </div>
        </HeadlessDialog>
    </HeadlessTransitionRoot>
</template>
