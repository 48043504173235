import { default as assigneeFbUyy8S11xMeta } from "/app/centre-supervisor-nuxt/pages/assignee.vue?macro=true";
import { default as assignmentjsPSekFYp6Meta } from "/app/centre-supervisor-nuxt/pages/assignment.vue?macro=true";
import { default as attendance6MZSDCjfJjMeta } from "/app/centre-supervisor-nuxt/pages/attendance.vue?macro=true";
import { default as centreSummaryReport7sbOx4o5R2Meta } from "/app/centre-supervisor-nuxt/pages/centreSummaryReport.vue?macro=true";
import { default as createChatGroupvNNGez1kkUMeta } from "/app/centre-supervisor-nuxt/pages/createChatGroup.vue?macro=true";
import { default as discrepancyReportsRm8rzyNZIsMeta } from "/app/centre-supervisor-nuxt/pages/discrepancyReports.vue?macro=true";
import { default as loginQCanaXw9P4Meta } from "/app/centre-supervisor-nuxt/pages/login.vue?macro=true";
import { default as messageBoardOnlyExamrOzQZWewhAMeta } from "/app/centre-supervisor-nuxt/pages/messageBoardOnlyExam.vue?macro=true";
import { default as sessionalReportsoefb20K7gyMeta } from "/app/centre-supervisor-nuxt/pages/sessionalReports.vue?macro=true";
import { default as soleQrCodeVKs9dYBmuRMeta } from "/app/centre-supervisor-nuxt/pages/soleQrCode.vue?macro=true";
import { default as specialCases8kNMxR3KQQMeta } from "/app/centre-supervisor-nuxt/pages/specialCases.vue?macro=true";
import { default as specialRoomSummaryReport32wlT8gKYDMeta } from "/app/centre-supervisor-nuxt/pages/specialRoomSummaryReport.vue?macro=true";
export default [
  {
    name: "assignee",
    path: "/assignee",
    component: () => import("/app/centre-supervisor-nuxt/pages/assignee.vue")
  },
  {
    name: "assignment",
    path: "/assignment",
    component: () => import("/app/centre-supervisor-nuxt/pages/assignment.vue")
  },
  {
    name: "attendance",
    path: "/attendance",
    component: () => import("/app/centre-supervisor-nuxt/pages/attendance.vue")
  },
  {
    name: "centreSummaryReport",
    path: "/centreSummaryReport",
    component: () => import("/app/centre-supervisor-nuxt/pages/centreSummaryReport.vue")
  },
  {
    name: "createChatGroup",
    path: "/createChatGroup",
    component: () => import("/app/centre-supervisor-nuxt/pages/createChatGroup.vue")
  },
  {
    name: "discrepancyReports",
    path: "/discrepancyReports",
    component: () => import("/app/centre-supervisor-nuxt/pages/discrepancyReports.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/centre-supervisor-nuxt/pages/login.vue")
  },
  {
    name: "messageBoardOnlyExam",
    path: "/messageBoardOnlyExam",
    component: () => import("/app/centre-supervisor-nuxt/pages/messageBoardOnlyExam.vue")
  },
  {
    name: "sessionalReports",
    path: "/sessionalReports",
    meta: sessionalReportsoefb20K7gyMeta || {},
    component: () => import("/app/centre-supervisor-nuxt/pages/sessionalReports.vue")
  },
  {
    name: "soleQrCode",
    path: "/soleQrCode",
    component: () => import("/app/centre-supervisor-nuxt/pages/soleQrCode.vue")
  },
  {
    name: "specialCases",
    path: "/specialCases",
    component: () => import("/app/centre-supervisor-nuxt/pages/specialCases.vue")
  },
  {
    name: "specialRoomSummaryReport",
    path: "/specialRoomSummaryReport",
    component: () => import("/app/centre-supervisor-nuxt/pages/specialRoomSummaryReport.vue")
  }
]