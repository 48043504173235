<script setup lang='ts'>

type Props = {
    examSessionId: ExamStore['examSessionId'],
    examYear: ExamStore['examYear'],
    centre: ExamStore['centre']
    centreCode: ExamStore['centreCode']
    subject: ExamStore['subject']
    subjectCode: ExamStore['subjectCode']
    paperCode: ExamStore['paperCode'],
    examDate: ExamStore['examDate'],
    centreType: ExamStore['centreType'],
    schedule: ExamStore['schedule'],
    isMaster: ExamStore['isMaster'],
}

const props = defineProps<Props>()

const locale = useLocale()
const dayjs = useDayjs()

const formatDateByLocale = (date: string, locale: string, fallback = '- -') => {
    const format = locale === 'hk' ? 'YYYY年M月DD日' : 'DD MMM YYYY'
    return date
        ? dayjs(date).format(format)
        : fallback
}

const formatTime = (timestamp: string) => {
    return dayjs(timestamp).format('HH:mm')
}

const examDetails = computed(() => [
    { title: 'exam.exam', value: 'DSE ' + props.examYear, isDisplayed: true },
    { title: 'exam.exam_centre', value: `${props.centreCode} - ${props.centre[locale.value]}`, isDisplayed: true },
    { title: 'exam.subject', value: `${props.subjectCode} - ${props.subject[locale.value]}`, isDisplayed: true },
    { title: 'exam.paper', value: props.paperCode, isDisplayed: true },
    { title: 'exam.exam_date', value: props.examDate ? formatDateByLocale(props.examDate, locale.value) : '- -', isDisplayed: true },
    { title: 'exam.scheduled_starting_time', value: props.schedule.start ? formatTime(props.schedule.start) : '- -', isDisplayed: props.centreType !== 'CLASSROOM' },
    { title: 'exam.scheduled_ending_time', value: props.schedule.end ? formatTime(props.schedule.end) : '- -', isDisplayed: props.centreType !== 'CLASSROOM' }
])

</script>

<template>
    <div class='flex flex-1 rounded-lg bg-white text-blue-900 2xl:text-lg'>
        <div class='flex items-center rounded-l-lg bg-orange-400 text-xl font-bold text-white'>
            <div
                class='flex flex-col items-center px-2 text-center'
                style='min-width: 110px;'
            >
                <ExclamationMark />
                <div>{{ $t('exam.exam_information') }}</div>
            </div>
        </div>
        <div
            v-if='examDetails'
            class='flex w-full flex-col justify-center p-5 font-medium'
        >
            <div
                v-for='detail in examDetails.filter((detail) => detail.isDisplayed)'
                :key='detail.title'
                class='flex'
            >
                <span class='w-1/2 lg:mr-2 xl:mr-3'>
                    {{ $t(detail.title) + ':' }}
                </span>

                <ClientOnly v-if='detail.title.endsWith("_date") || detail.title.endsWith("_time")'>
                    <span class='w-1/2 lg:mb-2 xl:mb-3 2xl:ml-4'>
                        {{ detail.value }}
                    </span>
                </ClientOnly>

                <span v-else class='w-1/2 lg:mb-2 xl:mb-3 2xl:ml-4'>
                    {{ detail.value }}
                </span>
            </div>
            <!-- <ClassroomInstruction v-if='props.centreType === "CLASSROOM"' :exam-session-id='props.examSessionId' /> -->
            <!-- <HallMasterInstruction
                v-if='props.isMaster'
                :exam-session-id='props.examSessionId'
            />
            <HallInstruction
                v-else
                :exam-session-id='props.examSessionId'
            /> -->
            <!-- <HallInstruction :exam-session-id='props.examSessionId' /> -->
            <!--<ClassroomReportChanges /> -->
        </div>
    </div>
</template>
