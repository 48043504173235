import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

type DateClassParams = ConstructorParameters<typeof Date>

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc)

/**
 * ISO string                      2022-07-06T16:00:00.000Z (UTC)
 * Year-Month-Date    (Ymd) string 2022-07-7                (HK)
 * Hour-Minute-Second (Hms) string 00:00:00                 (HK)
 * Hour-Minute        (Hm)  string 00:00                    (HK)
 * @param number
 */
export function getTwoDigitNumber(number: number) {
    return number.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    })
}

function getHkTime(timeString: Parameters<typeof dayjs>[0]) {
    // helper function to output time string with HKT offset (UTC+8)
    // e.g. 2000-01-01T08:00:00+08:00
    return dayjs.utc(timeString).utcOffset(8).format()
}

export function getHkYmd(timeString: Parameters<typeof getHkTime>[0]) {
    // get YYYY-MM-DD in HKT
    const hkTime = getHkTime(timeString)
    return hkTime.split('T')[0]
}

export function getHkHms(timeString: Parameters<typeof getHkTime>[0]) {
    // get HH:MM:SS in HKT
    const hkTime = getHkTime(timeString)
    return hkTime.split('T')[1].split('+')[0]
}

export function getHkHm(timeString: Parameters<typeof dayjs>[0]) {
    // get HH:MM in HKT
    return dayjs(timeString).format('HH:mm')
}

export function getYmd(iso: DateClassParams[0]) {
    // e.g. 2022-08-22T16:00:00.000Z string
    // expected 2022-08-23 in Hong Kong
    const date = new Date(iso)
    return date.getFullYear() + '-' + getTwoDigitNumber(date.getMonth() + 1) + '-' + getTwoDigitNumber(date.getDate())
}

export function getHms(iso: DateClassParams[0]) {
    const date = new Date(iso)
    return getTwoDigitNumber(date.getHours()) + ':' + getTwoDigitNumber(date.getMinutes()) + ':' + getTwoDigitNumber(date.getSeconds())
}

export function getNumbers(iso: DateClassParams[0]) {
    const date = new Date(iso)
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds()
    }
}

export function getYmdLimitedOptions(iso: Parameters<typeof getNumbers>[0]) {
    // yesterday, today and tomorrow
    const years = new Set()
    const months = new Set()
    const days = new Set()
    const today = getNumbers(iso)
    const yesterday = getNumbers(new Date(new Date(iso).getTime() - 24 * 60 * 60 * 1000).toISOString())
    const tomorrow = getNumbers(new Date(new Date(iso).getTime() + 24 * 60 * 60 * 1000).toISOString())
    years.add(yesterday.year).add(today.year).add(tomorrow.year)
    months.add(yesterday.month).add(today.month).add(tomorrow.month)
    days.add(yesterday.day).add(today.day).add(tomorrow.day)
    return {
        years: Array.from(years),
        months: Array.from(months),
        days: Array.from(days)
    }
}

export function getCompleteTime(iso: Parameters<typeof getHkYmd>[0] & Parameters<typeof getHkHms>[0]) {
    return getHkYmd(iso) + ', ' + getHkHms(iso)
}

export function getHm(iso: DateClassParams[0]) {
    const date = new Date(iso)
    return getTwoDigitNumber(date.getHours()) + ':' + getTwoDigitNumber(date.getMinutes())
}

// use T to support safari
export function getIso(ymd: string, hms: string) {
    // +0800 is needed as the API return exam_time in HKT
    return new Date(ymd + 'T' + hms + '+0800').toISOString()
}

/**
 * startTime ISO string 2022-07-11T00:30:00.000Z (UTC)
 * duration             8040 (seconds)
 * endTime ISO string   2022-07-11T02:44:00.000Z (UTC)
 * @param startTime
 * @param durationInMs
 */
export function getExpectedEndedTimestamp(startTime: DateClassParams[0], durationInMs: number) {
    const startTimestamp = new Date(startTime)
    return new Date(startTimestamp.getTime() + durationInMs)
}

export function getPreviousTimestamp(hour: number, minute: number, second: number) {
    // e.g. 5 6 7
    let base = new Date() // now, e.g. Thu Jul 28 2022 15:52:53 GMT+0800 (Hong Kong Standard Time)
    const currentHour = base.getHours() // e.g. 15 52 53
    const currentMinute = base.getMinutes()
    const currentSecond = base.getSeconds()
    if (hour > currentHour || (hour === currentHour && minute > currentMinute) || (hour === currentHour && minute === currentMinute && second > currentSecond)) {
        base = new Date(base.getTime() - 24 * 60 * 60 * 1000)
    }
    base.setHours(hour)
    base.setMinutes(minute)
    base.setSeconds(second)
    // e.g. Date Thu Jul 28 2022 05:06:07 GMT+0800 (Hong Kong Standard Time)
    return base.toISOString()
}

export function getTimestamp(year: number, month: number, day: number, hour: number, minute: number, second: number) {
    return new Date(year, month - 1, day, hour, minute, second, 0).toISOString()
}

// get a timestamp of start time
export function getSameDateTimestamp(oneDateIso: DateClassParams[0], hour: number, minute: number, second: number) {
    const base = new Date(oneDateIso)
    base.setHours(hour)
    base.setMinutes(minute)
    base.setSeconds(second)
    return base.toISOString()
}

// EAA said select a time of today,
export function getTodayTimestamp(hour: number, minute: number, second: number) {
    const base = new Date()
    base.setHours(hour)
    base.setMinutes(minute)
    base.setSeconds(second)
    return base.toISOString()
}

// to decide whether CS can edit information
export function getTomorrowFirstTimestamp(todayIso: DateClassParams[0]) {
    const today = new Date(todayIso)
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)
    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000)
    return tomorrow.toISOString()
}

export function padNumber(num: number, digits = 2) {
    return num.toString().padStart(digits, '0') as `${number}`
}

export function ddmmyyyy(date: Parameters<typeof dayjs>[0]) {
    return dayjs(date).format('DD MMM YYYY')
}

export function getDisplayTime<F extends string | undefined>(ts: DateClassParams[0] | null | undefined, fallback?: F) {
    const date = ts && new Date(ts)

    if (date) {
        const todayDate = ddmmyyyy(new Date())
        const inputDate = ddmmyyyy(date)

        const hours = padNumber(date.getHours())
        const minutes = padNumber(date.getMinutes())
        const seconds = padNumber(date.getSeconds())
        const inputTime = `${hours}:${minutes}:${seconds}`

        if (todayDate === inputDate) {
            return inputTime
        }

        return `${inputDate}, ${inputTime}`
    }

    return fallback
}
